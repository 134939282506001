import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseTransformer,
} from 'axios';
import { request } from 'http';
import { graphUserSearchValueType, searchResultVmType } from '../models/Graph';
import { ItdUserAssignmentVm } from '../models/ItdAdminAssignmentVm';
import httpClient, { requestOnce } from '../utils/httpClient';

const responseBody = (response: AxiosResponse) => response;

const requests = {
  get: (url: string) => httpClient.get(url).then(responseBody),
  post: (accessToken: string, url: string, body: any) =>
    httpClient
      .post(url, body, {
        headers: {
          ContentType: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(responseBody),
  getFull: (accessToken: string, url: string) =>
    httpClient
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(responseBody),
  delete: (accessToken: string, url: string) =>
    httpClient.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
};
export default function setUserIsAdmin(
  accessToken: string,
  assignment: ItdUserAssignmentVm
): Promise<AxiosResponse> {
  return requests.post(accessToken, '/api/dept/user', assignment);
}

export const searchUser = async (
  searchUrl: string,
  graphToken: string
): Promise<any> => {
  const config: AxiosRequestConfig = {
    method: 'get',
    url: searchUrl,
    headers: {
      'Content-Type': 'text/json',
      Authorization: `Bearer ${graphToken}`,
    },
    transformResponse: [
      (data, headers) => {
        const _data = JSON.parse(data);

        const _dataEnhanced = _data.value.reduce(
          (
            acc: searchResultVmType[],
            item: graphUserSearchValueType,
            index: number
          ) => {
            acc.push({ ...item, isGroupAdmin: false });
            return acc;
          },
          []
        );
        return JSON.stringify(_dataEnhanced);
      },
      ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    ],
  };

  const res = await requestOnce({ config });
  return res.data;
};

export function impersonateUser(
  accessToken: string,
  impersonatedAzureId: string
): Promise<AxiosResponse> {
  return requests.post(accessToken, '/api/admin/impersonate', {
    impersonatedUser: impersonatedAzureId,
  });
}

export function profileData(accessToken: string): Promise<AxiosResponse> {
  return requests.getFull(accessToken, '/api/user/me/profileData');
}

export function clearImpersonation(
  accessToken: string
): Promise<AxiosResponse> {
  return requests.delete(accessToken, '/api/admin/impersonate');
}

export function getUser(
  accessToken: string,
  userId: number
): Promise<AxiosResponse> {
  return requests.getFull(accessToken, `/api/user/${userId}?resolve=true`);
}
