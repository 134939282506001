import _ from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EvStationIcon from '@mui/icons-material/EvStation';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { CardActionArea, CardMedia, Stack } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import InfoCard from '../../../components/Card/InfoCard';
import useAccessToken from '../../../hooks/useAccessToken';
import useNextReservations from '../../../hooks/useNextReservation';
import useNotifier from '../../../hooks/useNotifier';
import { ProfileType } from '../../../models/Access';
import {
  CarType,
  FuelLevel,
  FuelType,
  ReservationType,
} from '../../../models/FleetAsset';
import { ApplicationState } from '../../../store';
import { AccessState } from '../../../store/Access';
import { backdropActions } from '../../../store/ApplicationBackdrop';
import httpClient from '../../../utils/httpClient';
import { toHumanFriendlyDate } from '../../../utils/timeHelper';
import { HomeContext } from '../HomeContextView';
import CheckOutWrapper from './CheckOutWrapper';

const CHECKOUT_RESERVATION_URL = (reservationId: string | number) =>
  `/api/Reservation/${reservationId}/checkout`;

const getFuelLevelComponent = (
  endingLevel: number = FuelLevel.Empty
): React.ReactNode => {
  const fuelLevel: { [index: number]: React.ReactNode } = {
    [FuelLevel.Empty]: (
      <Typography sx={{ fontSize: 14 }} color='error'>
        EMPTY
      </Typography>
    ),
    [FuelLevel.Quarter]: (
      <Typography sx={{ fontSize: 14 }}>
        <sup>1</sup>&frasl;<sub>4</sub>
      </Typography>
    ),
    [FuelLevel.Half]: (
      <Typography sx={{ fontSize: 14 }}>
        <sup>1</sup>&frasl;<sub>2</sub>
      </Typography>
    ),
    [FuelLevel.ThreeQuarter]: (
      <Typography sx={{ fontSize: 14 }}>
        <sup>3</sup>&frasl;<sub>4</sub>
      </Typography>
    ),
    [FuelLevel.Full]: (
      <Typography sx={{ fontSize: 14 }} color='green'>
        FULL
      </Typography>
    ),
  };

  return fuelLevel[endingLevel];
};

const getFuelIcon = (fuelType: string = FuelType.Unleaded): React.ReactNode => {
  const fuelIcon: { [index: string]: React.ReactNode } = {
    [FuelType.Unleaded.toLowerCase()]: <LocalGasStationIcon fontSize='small' />,
    [FuelType.Diesel.toLowerCase()]: <LocalGasStationIcon fontSize='small' />,
    [FuelType.Electric.toLowerCase()]: <EvStationIcon fontSize='small' />,
  };

  return (
    fuelIcon[fuelType.toLocaleLowerCase()] ||
    fuelIcon[FuelType.Unleaded.toLocaleLowerCase()]
  );
};

const mobileContent = (
  vehicle: CarType,
  profile: ProfileType | null,
  onClickCb: Function,
  checkedOut: boolean
): React.ReactNode => {
  let shouldDisableClick: boolean = false;

  if (checkedOut && profile) {
    shouldDisableClick = profile?.id !== vehicle.checkedOut?.userId;

    if (profile.isDeptAdmin || profile.isItdadmin || profile.groupAdmin) {
      shouldDisableClick = false;
    }
  }

  return (
    <CardActionArea
      sx={{ width: '100%' }}
      disabled={shouldDisableClick}
      onClick={(e: any) => {
        e.preventDefault();
        onClickCb(vehicle);
      }}
      key={`${vehicle.assetNumber}`}
      id={`${vehicle.assetNumber}`}
      data-id={`${vehicle.assetNumber}`}
    >
      <CardContent sx={{ paddingBottom: '5px', paddingTop: '5px' }}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item xs={9}>
            <Grid container spacing={0} direction='column'>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 14 }}>
                  <b>{vehicle.assetNumber ? vehicle.assetNumber : 'na'}</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 14 }}>
                  {`${vehicle.year} ${vehicle.make} `}
                  {vehicle.model &&
                    (vehicle.model?.length > 15
                      ? `${vehicle.model?.substring(0, 14)}...`
                      : vehicle.model)}
                </Typography>
              </Grid>
              <Grid item xs={12} pt={0}>
                <Typography alignItems='center' sx={{ display: 'flex' }}>
                  {checkedOut ? (
                    <Stack direction='column' alignItems='center'>
                      <Typography variant='caption'>
                        <b>{`${vehicle.checkedOut?.userName}`}</b>
                      </Typography>
                      {/* <Typography variant='caption'>{`${checkedOutEtr(
                        vehicle.checkedOut?.expectedCheckInDate
                      )}`}</Typography> */}
                    </Stack>
                  ) : (
                    <></>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {checkedOut ? (
            <Grid item xs={3} mr={0} mt={-1} justifyItems='right'>
              <Typography variant='caption'>
                <b>{`${checkedOutEtr(
                  vehicle.checkedOut?.expectedCheckInDate,
                  true
                )}`}</b>
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={3} mr={0} mt={-1} justifyItems={'right'}>
              <Stack
                mt={2.2}
                direction='column'
                alignItems='center'
                spacing={1}
              >
                {getFuelIcon(vehicle.fuelType ?? FuelType.Unleaded)}
                {getFuelLevelComponent(vehicle.endingFuelLevel)}
              </Stack>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </CardActionArea>
  );
};

const content = (
  vehicle: CarType,
  profile: ProfileType | null,
  onClickCb: Function,
  checkedOut: boolean,
  nextReservation?: ReservationType | null
): React.ReactNode => {
  //write a function that return false if nextReservation is null otherwise nextReservation.user == profile.id
  function isDisabled() {
    if (nextReservation === null || nextReservation === undefined) {
      return false;
    }
    return nextReservation.user !== profile?.id;
  }

  let disabled: boolean = isDisabled();

  let shouldDisableClick: boolean = disabled;

  if (checkedOut && profile) {
    shouldDisableClick = profile?.id !== vehicle.checkedOut?.userId;

    if (profile.isDeptAdmin || profile.isItdadmin || profile.groupAdmin) {
      shouldDisableClick = false;
    }
  }

  return (
    <CardActionArea
      sx={{ width: '100%' }}
      disabled={shouldDisableClick}
      onClick={(e: any) => {
        e.preventDefault();
        onClickCb(nextReservation ? nextReservation.id : vehicle);
      }}
      key={`${vehicle.assetNumber}`}
      id={`${vehicle.assetNumber}`}
      data-id={`${vehicle.assetNumber}`}
    >
      <CardContent sx={{ width: 195, paddingLeft: '0px', marginLeft: '0px' }}>
        <Grid
          container
          spacing={0}
          direction='column'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14 }}>
              <b>{vehicle.assetNumber ? vehicle.assetNumber : 'na'}</b>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14 }}>
              {`${vehicle.year} ${vehicle.make}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 14 }}>
              {vehicle.model &&
                (vehicle.model?.length > 15
                  ? `${vehicle.model?.substring(0, 14)}...`
                  : vehicle.model)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography alignItems='center' sx={{ display: 'flex' }}>
              {checkedOut ? (
                <Stack direction='column' alignItems='center'>
                  <Typography variant='caption'>
                    <b>{`${vehicle.checkedOut?.userName}`}</b>
                  </Typography>
                  <Typography variant='caption'>{`${checkedOutEtr(
                    vehicle.checkedOut?.expectedCheckInDate,
                    false
                  )}`}</Typography>
                </Stack>
              ) : (
                <Stack mt={2.2} direction='row' alignItems='center' spacing={1}>
                  {getFuelIcon(vehicle.fuelType ?? FuelType.Unleaded)}
                  {getFuelLevelComponent(vehicle.endingFuelLevel)}
                </Stack>
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </CardActionArea>
  );
};

interface CheckInProps {
  color?: string;
  carList: CarType[];
  handleCarClick: Function;
  checkedOut?: boolean;
  mobile?: boolean;
}

const COLOR_MAP = {
  default: '#c5ffae',
  assigned: '#FFC700',
  reserved: '#99ccff',
  checkedOut: '#FF6666',
};

export default function CheckInCheckOut(props: CheckInProps) {
  const {
    color = '#c5ffae',
    carList,
    handleCarClick,
    checkedOut = false,
    mobile = false,
  } = props;
  const dispatch = useDispatch();
  const nextRervations = useNextReservations(carList);
  const { reload } = useContext(HomeContext);
  const { createNotification } = useNotifier();
  const { accessToken } = useAccessToken();

  const default_image =
    'https://gsmechanical.com.au/wp-content/uploads/2018/01/AdobeStock_110205977-e1516765819712.jpeg';

  const { profile } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );

  const getColor = (
    vehicle: CarType,
    latestReservation: ReservationType | null
  ): string => {
    if (latestReservation) {
      return COLOR_MAP.reserved;
    } else if (vehicle.assignedTo) {
      return COLOR_MAP.assigned;
    } else if (vehicle.checkedOut) {
      return COLOR_MAP.checkedOut;
    } else {
      return COLOR_MAP.default;
    }
  };

  function HandleCheckoutReservation(reservationId: number) {
    dispatch(backdropActions.openBackDrop());
    httpClient
      .request({
        url: CHECKOUT_RESERVATION_URL(reservationId),
        method: 'POST',
        headers: {
          'Content-Type': 'text/json',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'json',
      })
      .then(() => {
        // refresh();
        if (reload !== null) reload();
        createNotification({
          message: 'Success',
          options: { variant: 'success' },
        });
        // setLoading(false);
      })
      .catch(() => {
        createNotification({
          message: 'Error during Checkout.',
          options: { variant: 'error' },
        });
        // setLoading(false);
      })
      .finally(() => {
        dispatch(backdropActions.closeBackDrop());
        window.location.reload();
      });
  }

  function getCBFunction(id: number): Function {
    return nextRervations[id] ? HandleCheckoutReservation : handleCarClick;
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <Grid container spacing={2} justifyContent='flex-start'>
          {carList.map((vehicle: CarType, index: number) => {
            return (
              <Grid item key={index}>
                {
                  <CheckOutWrapper
                    userId={nextRervations[vehicle.id]?.user ?? null}
                    text={
                      nextRervations[vehicle.id]
                        ? toHumanFriendlyDate(
                            nextRervations[vehicle.id]?.expectedStartTime
                          )
                        : ''
                    }
                  >
                    <InfoCard
                      image={
                        <CardMedia
                          component='img'
                          sx={{
                            width: mobile ? 75 : 135,
                            height: mobile ? 75 : 135,
                          }}
                          image={
                            vehicle.imageId === null
                              ? default_image
                              : `${vehicle.imageId}?${profile?.storageToken}`
                          }
                          alt='fleet vehicle'
                        />
                      }
                      content={
                        mobile
                          ? mobileContent(
                              vehicle,
                              profile,
                              handleCarClick,
                              checkedOut
                            )
                          : content(
                              vehicle,
                              profile,
                              getCBFunction(vehicle.id),
                              checkedOut,
                              nextRervations[vehicle.id]
                            )
                      }
                      width={305}
                      height={mobile ? 35 : 70}
                      color={getColor(vehicle, nextRervations[vehicle.id])}
                    />
                  </CheckOutWrapper>
                }
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
}

function checkedOutEtr(dateString: string | null | undefined, mobile: boolean) {
  if (dateString === null || dateString === undefined) {
    return '';
  }
  const date = new Date(dateString);
  if (mobile) {
    return moment(date).format('MMM D [\n] h:mma');
  } else {
    return moment(date).format('[ETR:] MMM D [@] h:mma');
  }
}
