import { CardActions } from '@mui/material';
import Card, { CardProps } from '@mui/material/Card';
import Color from 'color';
import _ from 'lodash';
import * as React from 'react';
import theme from '../../theme';

export interface InfoCardProps extends CardProps {
  color?: string;
  noBorders?: boolean;
  square?: boolean;
  blur?: number;
  width?: number;
  height?: number;
  content: React.ReactNode;
  image?: React.ReactNode;
  actions?: React.ReactNode[];
}

export default function InfoCard(props: InfoCardProps) {
  const {
    color = '#B8E3F5',
    blur = 25,
    width = 400,
    height = 227,
    noBorders,
    square,
    className,
    content,
    image = null,
    actions = null,
    ...rest
  } = props;

  const createActions = (): React.ReactNode => (
    <CardActions>
      {actions && actions.map((Action: React.ReactNode) => Action)}
    </CardActions>
  );

  const actionButtons = (): React.ReactNode =>
    _.isEmpty(actions) ? <></> : createActions();

  return (
    <Card
      
      sx={{
        display: 'flex',
        width: width,
        backgroundColor: Color(color).alpha(0.4).toString(),
        boxShadow: '10px 10px 10px rgba(30, 30, 30, 0.1)',
        borderRadius: theme.spacing(2),
        
      }}
    >
      {image}
      {content}
      {actionButtons()}
    </Card>
  );
}
