import { Column } from '@material-table/core';
import { CheckInCheckOutType } from './CheckInCheckOut';
import IAttachableObject from './IAttachableObject';
import { ProfileType } from './Access';
import { Chip } from '@mui/material';
import { formatMialage } from '../utils/carHelper';
import React from 'react';

export enum FuelLevel {
  Empty = 0,
  Quarter = 1,
  Half = 2,
  ThreeQuarter = 3,
  Full = 4,
}

export enum FuelType {
  Unleaded = 'Unleaded',
  Diesel = 'Diesel',
  Electric = 'Electric',
}

export interface CarNoteType {
  userId: number;
  userName: string;
  createdOn: string;
  note: string;
}

export interface ReservationType {
  id: number;
  expectedStartTime: string;
  expectedEndTime: string;
  user: number;
  car: number;
  purpose: string;
  createdOn: string;
}

export interface CarType extends IAttachableObject {
  assetId: number;
  deptNumber: string | null;
  importDate: string | null;
  id: number;
  make: string | null;
  model: string | null;
  year: number | null;
  vin: string | null;
  color: string | null;
  fuelType: string | null;
  notes: string | null;
  createdOn: string | null;
  updatedOn: string | null;
  active: true;
  licensePlate: string | null;
  restrictedToGroup: boolean | null;
  assignedTo: ProfileType | null;
  maintainedBy: string | null;
  assignedToNavigation: string | null;
  maintainedByNavigation: string | null;
  groupCars: any;
  assetNumber: string | null;
  tags: string[];
  checkedOut: CheckInCheckOutType | null;
  userNotes: CarNoteType[] | null;
  endingFuelLevel: number;
  imageId: string | null;
  makeModel: string;
  groups: string[];
  latestMileage: number | null;
  reservations?: ReservationType[];
}

export const vehicleTableColumns = (
  hideDept: boolean = true
): Array<Column<CarType>> => [
  { title: 'Assigned', field: 'assignedTo.name', defaultSort: 'desc' },
  { title: 'Asset Number', field: 'assetNumber' },
  { title: 'Active', field: 'active', lookup: { true: 'Active' } },
  {
    title: 'Mileage',
    field: 'latestMileage',
    render: (rowData) => formatMialage(rowData.latestMileage),
  },
  { title: 'License', field: 'licensePlate' },
  { title: 'Year', field: 'year' },
  { title: 'Make/Model', field: 'makeModel' },
  { title: 'Color', field: 'color' },
  { title: 'Fuel Type', field: 'fuelType' },
  { title: 'Dept. Number', field: 'deptNumber', hidden: hideDept },
  {
    title: 'Groups',
    field: 'groups',
    render: (rowData: CarType, type) => {
      if (type === 'row') {
        return (
          <>
            {rowData.groups.map((group: string) => (
              <Chip label={group} />
            ))}
          </>
        );
      } else if (type === 'group') {
        return (
          <>
            {
              //@ts-ignore
              rowData.map((group: string, index: number) => (
                <React.Fragment key={index}>
                  <Chip label={group} />
                </React.Fragment>
              ))
            }
          </>
        );
      }
    },
  },
];
