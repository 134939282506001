import React, { useContext, useEffect, useState } from 'react';
import Dialog from '../../../components/Dialog';
import { CarType } from '../../../models/FleetAsset';
import { HomeContext } from '../HomeContextView';
import CheckInCheckOut from './CheckInCheckOut';
import CheckOutForm from './CheckOutForm';

interface CheckOutModalProps {
  color?: string;
  carList: CarType[];
  mobile?: boolean;
}

export default function CheckOutModal({
  color = '#c5ffae',
  carList,
  mobile = false,
}: CheckOutModalProps) {
  const [openCheckOut, setOpenCheckOut] = useState<boolean>(false);
  const [selectedCar, setSelectedCar] = useState<CarType | null>(null);
  const { reload } = useContext(HomeContext);

  const handleCloseNoSave = () => {
    setOpenCheckOut(false);
    setSelectedCar(null);
  };

  const handleSave = () => {
    setOpenCheckOut(false);
    setSelectedCar(null);
    if (reload !== null) reload();
  };

  const handleOpen = (carSelected: CarType) => {
    setSelectedCar(carSelected);
  };

  useEffect(() => {
    let isMounted = true;

    if (selectedCar !== null) {
      setOpenCheckOut(true);
    }

    return () => {
      isMounted = false;
    };
  }, [selectedCar]);

  return (
    <>
      <Dialog
        open={openCheckOut}
        fullScreen={false}
        maxWidth={'md'}
        clickClose={handleCloseNoSave}
        title='Check Out'
      >
        {selectedCar !== null ? (
          <CheckOutForm
            car={selectedCar}
            closeCheckout={handleCloseNoSave}
            closeRefresh={handleSave}
          />
        ) : (
          <></>
        )}
      </Dialog>
      <CheckInCheckOut
        color={color}
        carList={carList}
        handleCarClick={handleOpen}
        mobile={mobile}
      />
    </>
  );
}
