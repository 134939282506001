import { Tooltip } from '@mui/material';
import React from 'react';
import useAccessToken from '../../../hooks/useAccessToken';
import { getUser } from '../../../services/user';
interface CheckOutWrapperProps {
  userId: number | null;
  text?: string;
  children: React.ReactNode;
}

export default function CheckOutWrapper({
  userId,
  text = '',
  children,
}: CheckOutWrapperProps) {
  const [displayText, setDisplayText] = React.useState<string>('');
  const { accessToken } = useAccessToken();

  React.useEffect(() => {
    let isMounded = true;
    if (userId !== null) {
      getUser(accessToken, userId).then((res) => {
        if (isMounded) setDisplayText(res.data.name);
      });
    }
    return () => {
      isMounded = false;
    };
  }, [userId, accessToken]);

  const toolipText =
    userId !== null ? (
      <table>
        <tr>
          <td style={{ width: '80px' }}>Click to</td>
          <td> check out</td>
        </tr>
        <tr>
          <td style={{ width: '80px' }}>Reserved</td>
          <td>{`${displayText}`}</td>
        </tr>
        <tr>
          <td style={{ width: '80px' }}>Time</td>
          <td>{`${text}`}</td>
        </tr>
      </table>
    ) : (
      ''
    );
  //
  return (
    <Tooltip title={toolipText} sx={{ maxWidth: 30 }}>
      <div>{children}</div>
    </Tooltip>
  );
}
