import { useState, useEffect } from 'react';
import { CarType, ReservationType } from '../models/FleetAsset';

// Custom Hook to check the next reservation for each car
const useNextReservations = (cars: CarType[]) => {
  const [nextReservations, setNextReservations] = useState<{
    [index: number]: ReservationType | null;
  }>({});

  useEffect(() => {
    const checkReservationsForAllCars = () => {
      const now = new Date();
      const twoHoursFromNow = new Date(now.getTime() + 120 * 60000); // 120 minutes in the future

      // Initialize an array to store the next reservation for each car
      cars.map((car: CarType) => {
        // Skip if there are no reservations for this car
        if (!car.reservations || car.reservations.length === 0) {
          // push carId: null to the nextReservation object
          setNextReservations((prev) => ({ ...prev, [car.id]: null }));
          return;
        }

        // Filter and sort the reservations within the next 60 minutes for this car
        const nextReservation = car.reservations
          .filter((reservation) => {
            const startTime = new Date(reservation.expectedStartTime);
            const fiveMinutesAfterStart = new Date(
              startTime.getTime() + 5 * 60000
            ); // 5 minutes after start time
            return startTime <= twoHoursFromNow && fiveMinutesAfterStart >= now;
          })
          .sort((a: ReservationType, b: ReservationType) => {
            const aDate = new Date(a.expectedStartTime);

            const bDate = new Date(b.expectedStartTime);
            return aDate.getUTCDate() - bDate.getUTCDate();
          });

        let _nextReserv: ReservationType | null = null;

        if (nextReservation.length > 0) {
          _nextReserv = nextReservation[0];
        }
        setNextReservations((prev) => ({
          ...prev,
          [car.id]: _nextReserv,
        }));

        return;
      });

      // Update state with the next reservation for each car
    };

    // Initial check
    checkReservationsForAllCars();

    // Check reservations every 60 seconds
    const intervalId = setInterval(checkReservationsForAllCars, 60000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [cars]); // Re-run when cars list changes

  return nextReservations;
};

export default useNextReservations;
